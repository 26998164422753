import { createMuiTheme } from "@material-ui/core"

export const theme = createMuiTheme({
  typography: {
    fontFamily: ["Open Sans"].join(","),
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "#ffffff",
      },
    },
    MuiContainer: {
      root: {
        background: "#e5e5e5",
      },
    },
  },
  palette: {
    primary: {
      main: "#0077A5",
    },
    secondary: {
      main: "#605555de",
    },
    text: {
      secondary: "#605555de",
    },
  },
})
